import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "info-tooltip" }


import BaseSvgIcon from "@/components/BaseSvgIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoTooltip',
  props: {
    text: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.text,
    placement: "right",
    effect: "light",
    class: "custom-tooltip"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", { innerHTML: _ctx.text }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(BaseSvgIcon, {
          class: "w-6 h-6 text-white",
          name: "tooltip"
        })
      ])
    ]),
    _: 1
  }, 8, ["content"]))
}
}

})