import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-grow" }
const _hoisted_2 = { class: "flex flex-col justify-center items-center lg:px-8 py-10" }
const _hoisted_3 = { class: "mt-6 w-full max-w-3xl py-4" }
const _hoisted_4 = { class: "text-xs py-6 px-8 shadow-lg rounded-lg bg-white" }
const _hoisted_5 = { class: "font-bold text-xl mb-4 text-neutral-dark" }
const _hoisted_6 = { class: "flex gap-4" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-sm font-bold mt-2 text-center w-40 text-neutral-dark" }
const _hoisted_9 = { class: "text-center gap-1 w-full text-base mt-4" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "flex items-center gap-4 justify-center mt-4" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["TheStep"], {
        currentStep: $setup.INVITATION_TIER_ONE.DETAILS,
        steps: $setup.invitationProgress
      }, null, 8, ["currentStep", "steps"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["Form"], {
          as: "el-form",
          "validation-schema": $setup.schema,
          "initial-values": $setup.formData,
          "label-position": "top",
          onSubmit: $setup.handleOnSubmit
        }, {
          default: _withCtx(({ isSubmitting, setFieldValue, setFieldError }) => [
            _createElementVNode("div", {
              class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h2", _hoisted_5, _toDisplayString($setup.SYSTEM.supply_chain_partner_company_information), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode($setup["Field"], { name: "BulkInviteFile" }, {
                    default: _withCtx(({ value, errorMessage }) => [
                      _createVNode(_component_el_form_item, {
                        error: errorMessage,
                        "model-value": value,
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            ($setup.formData.BulkInviteFile.length == 0)
                              ? (_openBlock(), _createBlock(_component_el_upload, {
                                  key: 0,
                                  class: "custom-upload",
                                  "auto-upload": false,
                                  "file-list": $setup.formData.BulkInviteFile,
                                  drag: "",
                                  multiple: "",
                                  limit: 2,
                                  "on-change": 
                      (file, showFileList) =>
                        $setup.onChangeFile(
                          'BulkInviteFile',
                          showFileList,
                          setFieldValue,
                          setFieldError
                        )
                    ,
                                  file: "",
                                  disabled: $setup.size(value as any) > 1 ,
                                  "on-preview": $setup.handlePreview,
                                  action: "",
                                  accept: ".csv",
                                  required: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                      _createVNode($setup["BaseSvgIcon"], {
                                        class: "w-20 h-20 text-neutral-dark",
                                        name: "UploadFile"
                                      }),
                                      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "upload_text" }, "Click to upload", -1))
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["file-list", "on-change", "disabled"]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createVNode($setup["FileDisplay"], {
                                    file: value[0],
                                    "is-edit": true,
                                    "is-invite": true,
                                    onUpdateFile: 
                              (fileList) =>
                                $setup.handleUpdateFile(
                                  fileList,
                                  'BulkInviteFile',
                                  setFieldValue
                                )
                            
                                  }, null, 8, ["file", "onUpdateFile"])
                                ])),
                            _createElementVNode("div", _hoisted_8, [
                              _createTextVNode(_toDisplayString($setup.SYSTEM.bulk_invite_file) + " ", 1),
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("button", {
                                  class: "underline text-file font-bold hover:text-blue-500",
                                  disabled: $setup.isDisableDownLoadTemplate,
                                  onClick: _withModifiers($setup.handleDownloadTemplate, ["prevent"])
                                }, " Download Template ", 8, _hoisted_10)
                              ])
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["error", "model-value"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode($setup["InfoTooltipVue"], { text: $setup.BULK_INVITE_TEMPLATE_TOOLTIP }, null, 8, ["text"])
                ])
              ])
            ], 2),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn bg-white border border-neutral-black hover:bg-gray-100 px-10 py-2",
                onClick: _withModifiers($setup.handleBack, ["prevent"])
              }, " Back "),
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn--green-primary px-8 py-2 disabled:bg-status-draft disabled:text-white",
                disabled: $setup.formData.BulkInviteFile.length === 0
              }, " Submit ", 8, _hoisted_12)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema", "initial-values"])
      ])
    ])
  ]))
}