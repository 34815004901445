import { defineComponent as _defineComponent } from 'vue'
import { useRouter } from "vue-router";
import { Field, Form } from "vee-validate";
import TheStep from "@/components/TheStep/TheStep.vue";
import { isEmpty, size } from "lodash";
import { object, mixed } from "yup";
import { INVITATION_TIER_ONE, invitationProgress } from "@ems/constants/steps";
import { SYSTEM } from "@ems/locales/system";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import {
  UPLOAD_FILE_BULK_INVITATION_VALIDATION,
  UPLOAD_FILE_VALIDATION,
} from "@ems/constants/validation_form";
import { ElMessage } from "element-plus";
import FileDisplay from "@/components/Common/FileHelper/FileDisplay.vue";
import { ref } from "vue";
import SupplyChainManamentModule from "@ems/containers/SupplyChainManagement/SupplyChainManament.module";
import {
  IBulkInvitationRequest,
  ITemplateResponse,
} from "@/models/SupplyChainManagement";
import { IFileMapObject } from "@/models/Company";
import { downloadResource } from "@/utils/helpers/downloadDocument";
import InfoTooltipVue from "@/components/Common/ToolTipHelper/InfoTooltip.vue";
import { BULK_INVITE_TEMPLATE_TOOLTIP } from "@ems/constants/tooltip";


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkInvitation',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const schema = object({
  BulkInviteFile: mixed().required().nullable(),
});

const formData = ref({
  BulkInviteFile: [],
});
const isDisableDownLoadTemplate = ref<boolean>(false);

const handleOnSubmit = async (values: any) => {
  formData.value.BulkInviteFile = [];
  await SupplyChainManamentModule.sendBulkInvitation(values);
  if (SupplyChainManamentModule.dataBulkInvitationGetter === true) {
    router.push("/brand-owner-information");
  }
};

const handleDownloadTemplate = async () => {
  isDisableDownLoadTemplate.value = true;
  await SupplyChainManamentModule.downloadInvitationTemplate();
  const downloadContent = SupplyChainManamentModule.dataDownloadTemplateGetter;
  if (downloadContent) {
    downloadResource(downloadContent.FileContent, downloadContent.FileName);
  }
  isDisableDownLoadTemplate.value = false;
};

const onChangeFile = (
  name: string,
  showFileList: any,
  setFieldValue: any,
  setFieldError: any
) => {
  // Get Newest File
  const newFile = showFileList[showFileList.length - 1];

  // Check File validation
  const isAllowedType = /\.(csv)$/i.test(newFile.name);
  const isLessThan25MB = newFile.size / 1024 / 1024 < 25;

  if (!isAllowedType || !isLessThan25MB) {
    showFileList.pop();

    const errorMessage = !isAllowedType
      ? UPLOAD_FILE_BULK_INVITATION_VALIDATION.TYPE
      : UPLOAD_FILE_VALIDATION.SIZE;

    if (showFileList.length === 0) {
      setFieldError(name, null);
      ElMessage.error(errorMessage);
    } else {
      setFieldError(name, null);
      ElMessage.error(errorMessage);
    }

    setFieldValue(name, showFileList);
    return;
  }
  formData.value.BulkInviteFile = showFileList;
  setFieldValue(name, showFileList);
};

const handleUpdateFile = (file: any, name: string, setFieldValue: any) => {
  formData.value.BulkInviteFile = [];
  formData.value.BulkInviteFile.push(file);
  setFieldValue(name, [file]);
};

const handlePreview = (uploadFile: any) => {
  window.open(URL.createObjectURL(uploadFile.raw));
};

const handleBack = () => {
  router.push("/brand-owner-information/send-invitation");
};

const __returned__ = { router, schema, formData, isDisableDownLoadTemplate, handleOnSubmit, handleDownloadTemplate, onChangeFile, handleUpdateFile, handlePreview, handleBack, get useRouter() { return useRouter }, get Field() { return Field }, get Form() { return Form }, TheStep, get isEmpty() { return isEmpty }, get size() { return size }, get object() { return object }, get mixed() { return mixed }, get INVITATION_TIER_ONE() { return INVITATION_TIER_ONE }, get invitationProgress() { return invitationProgress }, get SYSTEM() { return SYSTEM }, BaseSvgIcon, get UPLOAD_FILE_BULK_INVITATION_VALIDATION() { return UPLOAD_FILE_BULK_INVITATION_VALIDATION }, get UPLOAD_FILE_VALIDATION() { return UPLOAD_FILE_VALIDATION }, get ElMessage() { return ElMessage }, FileDisplay, ref, get SupplyChainManamentModule() { return SupplyChainManamentModule }, get IBulkInvitationRequest() { return IBulkInvitationRequest }, get ITemplateResponse() { return ITemplateResponse }, get IFileMapObject() { return IFileMapObject }, get downloadResource() { return downloadResource }, InfoTooltipVue, get BULK_INVITE_TEMPLATE_TOOLTIP() { return BULK_INVITE_TEMPLATE_TOOLTIP } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})